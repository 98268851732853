<template>
    <div class="merchantCenterList MERCH">
      <!-- 供应商中心 -->
      <div class="merch-card">
        <!-- 搜索列 -->
        <div class="merch-search">
          <el-form ref="searchParams" :model="searchParams" :inline="true">
            <el-form-item prop="supplier">
              <el-input
                maxlength="32"
                v-model="searchParams.supplier"
                placeholder="请输入账单编号"
                clearable
                @keyup.enter.native="handleCurrentChange"
              />
            </el-form-item>
            <el-form-item prop="supplier">
              <el-input
                maxlength="32"
                v-model="searchParams.supplier"
                placeholder="请输入账单单位"
                clearable
                @keyup.enter.native="handleCurrentChange"
              />
            </el-form-item>
            <el-form-item prop="complaint">
              <el-select
                clearable
                maxlength="32"
                v-model="searchParams.status"
                placeholder="请选择支付对账结果"
              >
                <el-option
                  v-for="item in payResult"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="complaint">
              <el-date-picker
                v-model="searchParams.complaint"
                type="datetimerange"
                range-separator="至"
                start-placeholder="请选择账单开始日期"
                end-placeholder="请选择账单结束日期"
                unlink-panels
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleCurrentChange" style="width: 80px">查 询</el-button>
              <el-button @click="handleReset" style="width: 80px" plain>重 置</el-button>
              <el-button type="primary" @click="handleCurrentChange">导 出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="sign">
        <!-- 左边 -->
            <div class="rightBtn">
                <div style="display: flex">
                <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    class="defaultStyle"
                    :class="searchParams.complainant === item.id ? 'opp' : ''"
                    @click="handleChange(item)"
                >
                    {{ item.Name }}
                </div>
                </div>
            </div>
            <div style="margin-right: 80px;color: #606266;">对账单数据文件在T+1天生成</div>
        </div>
        
        <!-- 列表 -->
        <div
          class="merch-table scorr-roll"
          :style="`max-height: ` + mainHright + `px`"
        >
          <div>
            <el-table :data="searchListData" border style="width: 100%">
              <el-table-column prop="id" label="序号" align="center" width="60"></el-table-column>
              <el-table-column prop="complainant" label="账单编号" align="center"></el-table-column>
              <el-table-column prop="telephone" label="收入金额（已结算）" align="center"></el-table-column>
              <el-table-column prop="createTime" label="待结算金额" align="center">
                <el-table-column prop="supplier" label="收入金额" align="center"></el-table-column>
                <el-table-column prop="supplier" label="支出金额" align="center"></el-table-column>
              </el-table-column>
              <el-table-column prop="supplier" label="异常流水" align="center"></el-table-column>
              <el-table-column prop="complaint" label="支付对账结果" align="center"></el-table-column>
              <el-table-column prop="mobile" label="账单单位" align="center"></el-table-column>
              <el-table-column prop="mobile" label="账单时间" align="center"></el-table-column>
              <el-table-column prop="complaintContent" label="操作" align="center" min-width="110">
                <template slot-scope="{row}">
                  <div class="userBtn">
                    <div @click="todetails(row)">
                      详情
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            background
            @current-change="searchBusinessList"
            layout="total, prev, pager, next, jumper"
            :total="total"
            :page-size=10
            :current-page="current_page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { createNamespacedHelpers } from "vuex";
  const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
  const merchantCenterList = createNamespacedHelpers("merchantCenterList");
  export default {
    data() {
      return {
          total:0,
          current_page:null,
          searchParams:{
              page:1,
              perPage:10,
              complainant:1,
              supplier:'',
              complaint:'',
          },
          statusList: [
            { Name: "平台", id: 1 },
            { Name: "供应商", id: 2 },
            { Name: "业务员", id: 3 },
          ],
          payResult:[],
          searchListData:[]
      }
    },
    computed: {
      ...commonIndex.mapState([ "mainHright", ]),
      ...merchantCenterList.mapState([ "page"]),
    },
    created(){
      this.getSearchList()
    },
    methods: {
      ...merchantCenterList.mapActions([
        "getComplainsRecords",
      ]),
      handleChange(){},
      handleReset(){},
      async getSearchList(){
        let {data} = await this.getComplainsRecords(this.searchParams)
        this.searchListData = data.data
        this.current_page = data.currentPage
        this.total = data.total
      },
      //当前页
      searchBusinessList(e) {
        if (e != undefined) {
          this.searchParams.page = e;
        }
        this.getSearchList()
      },
      //搜索
      handleCurrentChange() {
        this.searchParams.page = 1;
        this.getSearchList()
      },
    }
  };
  </script>
  <style lang="scss" scoped>
    .MERCH {
      width: 100%;
      height: 100%;

      .merch-card {
        width: 100%;
        max-height: 100%;
        padding: 20px;
        padding-top: 10px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        background: #ffffff;

        .merch-tag {
          width: 100%;
          height: 40px;
        }

        // 搜索行样式
        .merch-search {
          margin-top: 1%;
          width: 100%;

          .search-box {
            display: flex;
            flex-wrap: wrap;
          }

          .sch-1 {
            margin-top: 10px;
            width: 18%;
            min-width: 200px;
            display: flex;

            .sch-title {
              width: 40%;
              min-width: 80px;
              height: 100%;
              font-size: 13px;
              background-color: #f5f7fa;
              color: #909399;
              border: 1px solid #dcdfe6;
              border-right: none;
              border-radius: 4px 0px 0px 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 0px 0px 5px !important;
            }
          }

          .sch-2 {
            margin-right: 2%;
          }
        }
        .sign {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .opp {
            color: #06b7ae;
            border-radius: 5px;
            font-weight: bold;
            background-color: rgba($color: #2e3238, $alpha: 0.05);
        }
        .defaultStyle {
            width: 100px;
            padding: 10px 20px;
            text-align: center;
            line-height: 100%;
            font-size: 14px;
            cursor: pointer;
        }
        .merch-table {
          //   margin-top: 1%;
          width: 100%;
          height: 77%;
        }

        .merch-page {
          margin-top: 10px;
          width: 100%;
          display: flex;
          align-content: center;
          justify-content: right;
        }
      }
      .userBtn {
        display: flex;
        justify-content: center;
        div {
          margin-left: 5px;
          color: #1abca9;
          cursor: pointer;
        }
      }
}
  </style>
  